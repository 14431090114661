<!--
 * @Description: 
 * @Author: LiangYiNing
 * @Date: 2022-06-10 09:29:33
 * @LastEditTime: 2022-07-20 15:12:30
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="slect-real-Time-query-content">
    <e6-vr-select
      v-model="selectValue"
      :data="dataList"
      :loading="loading"
      :placeholder="placeholder"
      :title="title"
      @filterChange="handleLoadEmployee"
      @change="selectChange"
      @clear="clearHandle"
      :props="propsConfig"
      :multiple="multiple"
      :clearable="clearable"
      :disabled="disabled"
      remote
      :is-title="true"
    >
      <template v-slot:label="slotProps" v-if="showSlot">
        <div class="option-content">
          <span class="options-remark-label ellipsis">{{
            slotProps.node.label
          }}</span>
          <span class="option-remark ellipsis">{{
            slotProps.node.employeeNo && slotProps.node.employeeNo.slice(-8)
          }}</span>
        </div>
      </template>
    </e6-vr-select>
  </div>
</template>
<script>
import { typeOf, printError, get } from "@/utils/util";
let VALUE_TYPE_ARRAY = "Array";
import { findAllUser } from "@/api";

export default {
  name: "allUserSearch",
  data() {
    return {
      selectValue: "",
      loading: false,
      dataList: [], // 下拉选择数据
      currentData: []
    };
  },
  watch: {
    value() {
      if (this.testValueChange()) {
        this.selectValue = this.multiple ? this.value || [] : this.value;
      }
    },
    initValue: {
      immediate: true,
      handler(val) {
        if (val) {
          this.currentData = _.cloneDeep(val);
          let filterArr = this.filterData(this.dataList);
          if (filterArr.length) {
            this.dataList = [...filterArr, ...this.dataList];
          }
        }
      }
    }
  },
  props: {
    propsConfig: {
      type: Object,
      default: () => {
        return {
          id: "employeeId",
          label: "employeeName"
        };
      }
    },
    queryListAPI: {
      type: Function,
      default: findAllUser
    },
    showSlot: {
      type: Boolean,
      default: false
    },
    value: {
      type: [String, Number, Array]
    },
    clearable: {
      type: Boolean
    },
    placeholder: {
      type: String,
      default: "请选择员工"
    },
    title: {
      type: String,
      default: "请选择员工"
    },
    disabled: {
      type: Boolean
    },
    needInitDropDownList: {
      type: Boolean,
      default: true
    },
    // 需回显的数据
    initValue: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean
    }
  },
  created() {
    if (this.testValueChange()) this.selectValue = this.value;
    if (this.multiple) this.selectValue = this.value || [];
    if (this.needInitDropDownList) {
      this.loadEmployeeData();
    }
    if (this.initValue.length) {
      this.currentData = this.initValue;
      let filterArr = this.filterData(this.dataList);
      this.dataList = [...filterArr, ...this.dataList];
    }
  },
  methods: {
    //输入员工名称或者电话
    handleLoadEmployee: _.debounce(async function(val) {
      if (val) {
        this.loadEmployeeData(val);
      }
    }, 300),
    //加载加载数据
    async loadEmployeeData(val) {
      try {
        this.loading = true;
        let res = await this.queryListAPI({
          nameOrPhone: val
        });
        //获取客户名称
        let dataList = res.data;
        let filterArr = this.filterData(dataList);
        dataList = [...filterArr, ...dataList];
        this.dataList = dataList.map(item => {
          if (item.phone) {
            item.contactNamePhone = `${item.employeeName}(${item.phone})`;
          } else {
            item.contactNamePhone = `${item.employeeName}`;
          }
          return item;
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //判断回显的数据再查询列表是否有，如果有则不塞入数据，否则的话塞入数据
    filterData(dataList) {
      if (!this.currentData.length) return [];
      let fliterArr = this.currentData.filter(each => {
        return dataList.every(item => {
          return item.employeeId != each.employeeId;
        });
      });
      return fliterArr;
    },
    /*
     ** 处理后台返回的数据，默认 code=1 时，根据路径获取 object 中的属性值
     */
    getFreezeResponse(
      object,
      { path = "result", defaultVal = [], freeze = true, code = ["OK"] } = {}
    ) {
      if (code.includes(object.code)) {
        return get(object, path, defaultVal, freeze);
      } else {
        return defaultVal;
      }
    },
    // value 测试值是否在外部发生改变
    testValueChange() {
      if (this.multiple && typeOf(this.value) !== VALUE_TYPE_ARRAY) {
        throw new Error(
          "components 'selectRealTimeQuery' need value type is 'Array' that Attr is 'multiple'"
        );
      } else if (this.multiple) {
        return JSON.stringify(this.value) !== JSON.stringify(this.selectValue);
      }
      return this.value !== this.selectValue;
    },
    // 选择触发事件
    selectChange(val, dataSelected) {
      if (this.multiple) {
        if (typeOf(dataSelected) === VALUE_TYPE_ARRAY) {
          // 全选/取消全选时 dataSelected 为所选数组
          this.currentData = val ? [...this.dataList] : [];
        } else if (val) {
          // 选择一个
          this.currentData.push(dataSelected);
        } else {
          // 取消选择一个
          this.currentData = this.currentData.filter(item => {
            return item.employeeId !== dataSelected.employeeId;
          });
        }
      } else {
        // 单选
        this.currentData = [dataSelected];
      }
      this.$nextTick(() => {
        let value = this.selectValue;
        this.$emit("input", value);
        this.$emit("change", value, dataSelected);
      });
    },
    // 清空事件
    clearHandle() {
      let value = this.multiple ? [] : this.selectValue;
      this.currentData = [];
      this.$emit("input", value);
      this.$emit("clear", value, null);
    }
  }
};
</script>
<style lang="scss" scoped>
$--color-option-tips-1: #f89191; // 异常结束
$--color-option-remark-1: #909399; // 备注
.slect-real-Time-query-content {
  width: 100%;
  .e6-vr-select {
    width: 100%;
  }
}
/deep/.option-content {
  position: relative;
  .option-label {
    display: inline-block;
    width: calc(100% - 20px);
  }
  .option-tips {
    display: inline-block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    margin-top: 3px;
    font-size: 10px;
    color: $--color-option-tips-1;
    border: solid 1px $--color-option-tips-1;
    border-radius: 2px;
    text-align: center;
    vertical-align: top;
  }
  .option-remark {
    display: inline-block;
    height: 16px;
    width: 57px;
    line-height: 16px;
    margin-top: 3px;
    margin-right: 3px;
    font-size: 10px;
    color: $--color-option-remark-1;
    border-radius: 2px;
    text-align: right;
    vertical-align: top;
  }
  .options-remark-label {
    display: inline-block;
    width: calc(100% - 60px);
  }
}
</style>
