<!--
 * @Description: 添加/编辑管理员弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-02 18:34:10
 * @LastEditTime: 2022-07-01 09:26:36
 * @LastEditors: ChenXueLin
-->
<template>
  <!-- 添加管理员弹框 start -->
  <el-dialog
    v-dialogDrag
    :title="type == 'edit' ? '编辑管理员弹框' : '添加管理员弹框'"
    :visible.sync="addAdminDialog"
    width="500px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    v-loading="addAdminLoading"
    :element-loading-background="loadingBackground"
    custom-class="customer-dialog"
  >
    <el-form
      ref="addAdminForm"
      label-width="100px"
      :model="addAdminForm"
      :rules="addAdminFormRules"
    >
      <el-form-item label="用户" prop="userId">
        <all-user-search
          :disabled="type == 'edit'"
          v-model="addAdminForm.userId"
          clear
          placeholder="用户"
          title="用户"
          :propsConfig="{
            id: 'userId',
            label: 'employeeName'
          }"
        >
        </all-user-search>
      </el-form-item>
      <!-- prop="moduleId" -->
      <el-form-item label="设置权限" :inline="false">
        <e6-tree
          v-model="addAdminForm.moduleId"
          :list="treeData"
          :is-check="true"
          :is-open="true"
          issingleleaf
          ref="e6tree"
          :selectAndCheck="true"
        ></e6-tree>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="handleSubmit">确定</el-button>
    </div>
  </el-dialog>
  <!-- 添加管理员弹框  end -->
</template>

<script>
import { adminTree, addAdmin, updateAdmin, getAdminDetail } from "@/api";
import { printError } from "@/utils/util";
import base from "@/mixins/base";
import handleTree from "../mixins/index";
import allUserSearch from "@/components/allUserSearch";
export default {
  name: "",
  components: { allUserSearch },
  data() {
    return {
      addAdminLoading: false,
      addAdminForm: {
        userId: "",
        moduleId: []
      },
      addAdminFormRules: {
        userId: [
          {
            required: true,
            message: "请选择用户",
            trigger: ["blur", "change"]
          }
        ],
        moduleId: [
          {
            required: true,
            message: "请选择权限",
            trigger: ["blur", "change"]
          }
        ]
      },
      treeData: []
    };
  },
  props: {
    addAdminDialog: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "add"
    }
  },
  mixins: [base, handleTree],
  computed: {},
  watch: {
    addAdminDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getTreeData();
        }
      }
    }
  },
  created() {},
  methods: {
    //获取详情
    async getAdminDetail() {
      let hasParamsRoute = this.getHasParamsRoute();
      let id = hasParamsRoute.params.id;
      if (!id) {
        return;
      }
      try {
        this.addAdminLoading = true;
        let res = await getAdminDetail({ id });
        this.addAdminForm = res.data;
        this.addAdminForm.moduleId = this.addAdminForm.moduleIds.map(item => {
          return item.toString();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.addAdminLoading = false;
      }
    },
    //查询组织架构树
    async getTreeData() {
      try {
        let res = await adminTree();
        let treeData = res.data;
        this.handleTreeData(treeData ? [treeData] : []);
        if (this.type == "edit") {
          this.getAdminDetail();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addAdminLoading = false;
      }
    },
    /******添加/编辑管理员*********/
    //点击确定添加
    handleSubmit() {
      if (!this.addAdminForm.moduleId.length) {
        this.$message.warning("请设置权限");
        return;
      }
      this.$refs.addAdminForm.validate(valid => {
        if (valid) {
          if (this.type == "add") {
            this.saveAdmin();
          } else {
            this.updateAdmin();
          }
        }
      });
    },
    //添加管理员请求
    async saveAdmin() {
      try {
        this.addAdminLoading = true;
        let res = await addAdmin(this.addAdminForm);
        if (res.code == "OK") {
          this.$message.success("添加成功");
          this.$emit("refresh");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addAdminLoading = false;
      }
    },
    //修改管理员
    async updateAdmin() {
      try {
        this.addAdminLoading = true;
        let res = await updateAdmin(this.addAdminForm);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.$emit("refresh");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.addAdminLoading = false;
      }
    },
    //关闭弹框重置form
    closeDialog() {
      this.addAdminForm = {
        userId: "", //用户id
        moduleId: [] //权限id
      };
      this.$refs.addAdminForm.resetFields();

      this.$emit("handleClose");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.customer-dialog {
  .el-dialog__body {
    max-height: 600px;
    overflow-y: auto;
    padding: 20px;
    // .el-input {
    //   width: 2200px;
    // }
    // .el-textarea {
    //   width: 220px;
    // }
  }
}
</style>
