<!--
 * @Description: 管理员权限详情
 * @Author: ChenXueLin
 * @Date: 2021-10-18 14:47:13
 * @LastEditTime: 2022-04-22 14:38:49
 * @LastEditors: LiangYiNing
-->

<!-- 基本信息 -->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 头部状态 -->
          <detail-title-content
            :btnType="1"
            btnText="任务单状态:进行中"
            :showTime="false"
          >
            <template v-slot:rightButtons>
              <el-button
                type="primary"
                class="e6-icon-edit_line"
                title="编辑"
                @click="addAdmin"
              ></el-button>
              <el-button
                type="primary"
                @click="stopUse"
                v-if="adminDetail.userStatus == '正常'"
                >停用</el-button
              >
              <el-button type="primary" @click="enbleAdmin" v-else
                >启用</el-button
              >
              <el-button type="primary" @click="deleteAdmin">删除</el-button>
            </template>
          </detail-title-content>
          <!-- 基本信息 start-->
          <template>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                label-width="140px"
                label-position="right"
                style="margin-top:20px;"
                :inline="true"
              >
                <el-form-item label="用户ID">
                  {{ adminDetail.userId }}
                </el-form-item>
                <el-form-item label="用户名称">
                  {{ adminDetail.userName }}
                </el-form-item>
                <el-form-item label="创建人">
                  {{ adminDetail.createBy }}
                </el-form-item>
                <el-form-item label="创建时间">
                  {{ adminDetail.createTime }}
                </el-form-item>
                <el-form-item label="用户角色">
                  {{ adminDetail.userRole.join(",") }}
                </el-form-item>
              </el-form>
            </div>
            <!-- 基本信息 end  -->
          </template>
          <!-- 基本信息  end -->
          <!-- 角色权限  start -->
          <template>
            <div class="baseInfo-box">
              <div class="edit-title">可分配权限</div>
              <div class="treeData">
                <e6-tree
                  v-model="adminDetail.moduleIds"
                  :list="treeData"
                  :is-check="true"
                  :is-open="true"
                  issingleleaf
                  ref="e6tree1"
                  :selectAndCheck="true"
                ></e6-tree>
              </div>
            </div>
          </template>
        </div>
      </section>
    </div>
    <add-diolag
      :addAdminDialog="addAdminDialog"
      @handleClose="handleClose"
      @refresh="getAdminDetail"
      type="edit"
      :detailForm="detailForm"
    ></add-diolag>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import {
  getAdminDetail,
  adminTree,
  adminDisEnable,
  deleteAdmin,
  adminEnable
} from "@/api";
import detailTitleContent from "@/components/detailTitleContent";
import addDiolag from "./addDiolag.vue";
import handleTree from "../mixins/index";
export default {
  name: "",
  data() {
    return {
      loading: false,
      adminDetail: {
        userRole: []
      },
      detailForm: {
        moduleId: []
      },
      treeData: [],
      addAdminDialog: false
    };
  },
  mixins: [base, handleTree],
  computed: {},
  components: { detailTitleContent, addDiolag },
  watch: {},
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.id = hasParamsRoute.params.id;
    if (this.id) {
      this.getTreeData();
    }
  },
  methods: {
    //获取管理员列表
    async getAdminDetail() {
      try {
        this.loading = true;
        let res = await getAdminDetail({ id: this.id });
        let adminDetail = res.data;
        this.adminDetail = _.cloneDeep(adminDetail);
        this.detailForm = adminDetail;
        this.adminDetail.moduleIds = this.adminDetail.moduleIds.map(item => {
          return item.toString();
        });
        this.detailForm.moduleId = this.detailForm.moduleIds.map(item => {
          return item.toString();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //查询组织架构树
    async getTreeData() {
      try {
        let res = await adminTree();
        let treeData = res.data;
        this.handleTreeData(treeData ? [treeData] : []);
        this.getAdminDetail();
      } catch (error) {
        printError(error);
      }
    },
    //启用管理员
    async enbleAdmin() {
      try {
        this.loading = true;
        let res = await adminEnable({
          id: this.adminDetail.managerId,
          versionNumber: this.adminDetail.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.getTreeData();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //停用管理员
    async stopUse() {
      try {
        let res = await adminDisEnable({
          id: this.adminDetail.managerId,
          versionNumber: this.adminDetail.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.getTreeData();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //删除管理员
    deleteAdmin() {
      this.$confirm("删除管理员", "是否确定删除此管理员？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteAdminReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //删除请求
    async deleteAdminReq() {
      try {
        let res = await deleteAdmin({
          id: this.adminDetail.managerId,
          versionNumber: this.adminDetail.versionNumber
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.routerPush({
            name: "userManage/adminManage",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //点击添加管理员
    addAdmin() {
      this.addAdminDialog = true;
    },
    //关闭弹框
    handleClose() {
      this.addAdminDialog = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.treeData {
  max-height: 550px;
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
}
.baseInfo-box {
  /deep/.el-form-item__content {
    max-width: 70% !important;
  }
}
</style>
