/*
 * @Description: 用户模块混入
 * @Author: ChenXueLin
 * @Date: 2021-12-07 10:09:12
 * @LastEditTime: 2021-12-08 10:05:57
 * @LastEditors: ChenXueLin
 */
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  watch: {},
  created() {},
  methods: {
    //处理树形结构
    handleTreeData(data) {
      // 第二种 修改涉及级联选择器 最后一个空白的情况
      // 循环遍历json数据
      for (var i = 0; i < data.length; i++) {
        if (data[i].personSum) {
          data[i]["label"] = data[i].name + "(" + data[i].personSum + ")";
        } else {
          data[i]["label"] = data[i].name;
        }
        if (data[i].children && data[i].children.length) {
          this.handleTreeData(data[i].children);
        }
      }
      this.treeData = data;
    }
  }
};
